import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link, graphql, useStaticQuery } from "gatsby";


const Partner = () => {

    const data_partner = useStaticQuery(graphql`
    query PartnersQuery {
        allPartnerJson {
          nodes {
            slug
            title
            subtitle
            description
            title_2
            where {
                title
                elements {
                    title
                    description
                }
            }
            contatti {
                title
                contacts {
                    title
                }
                links {
                    title
                    type
                    url
                }
            }
          }
        }
      }
    `)
    const partner = data_partner.allPartnerJson.nodes[0]

    const title = partner.title
    const subtitle = partner.subtitle
    const description = partner.description
    const title_2 = partner.title_2
    const where = partner.where
    const contatti = partner.contatti
    return (
        <div id="partners" className="xl:px-24 pb-[60px]">
            <div className="flex flex-col justify-center items-start pb-[60px] px-6 md:px-0 xl:items-center ">
                <h3 className="text-2xl text-center text-sbam-2 font-mulish font-extrabold pb-4 self-center">{title}</h3>
                <h2 className="text-4xl font-normal pb-10 text-center md:text-[40px] md:leading-[46px] xl:max-w-[684px] " dangerouslySetInnerHTML={{ __html: subtitle }}></h2>
                <p className="text-lg font-mulish text-center font-medium xl:max-w-[684px] xl:text-[22px]">
                {description}
                </p>
            </div>
            <div className="flex justify-center items-center gap-5 gap-y-8 flex-wrap">
                <StaticImage placeholder='none' className="logo-menu-mob w-1/3 md:w-1/4 lg:w-1/5" src="../images/partner-abita-cooperativa-sociale.png" alt="Partner Abita" />
                <StaticImage placeholder='none' className="logo-menu-mob w-1/3 md:w-1/4 lg:w-1/5" src="../images/partner-aeper-cooperativa-sociale.png" alt="Partner AEPER" />
                <StaticImage placeholder='none' className="logo-menu-mob w-1/3 md:w-1/4 lg:w-1/5" src="../images/partner-biplano-cooperativa.png" alt="Partner Biplano" />
                <StaticImage placeholder='none' className="logo-menu-mob w-1/3 md:w-1/4 lg:w-1/5" src="../images/partner-gasparina-societa-cooperativa.png" alt="Partner Gasparina" />
                <StaticImage placeholder='none' className="logo-menu-mob w-1/3 md:w-1/4 lg:w-1/5" src="../images/partner-generazioni-fa.png" alt="Partner Generazioni" />
                <StaticImage placeholder='none' className="logo-menu-mob w-1/3 md:w-1/4 lg:w-1/5" src="../images/partner-pugno-aperto.png" alt="Partner Pugno Aperto" />
                <StaticImage placeholder='none' className="logo-menu-mob w-1/3 md:w-1/4 lg:w-1/5" src="../images/partner-cooperativa-ruah.png" alt="Partner Cooperativa Ruah" />
            </div>
            <div id="contact-us">
                <div className="flex flex-col justify-center pb-[60px] pt-[170px]">
                    <h2 className="text-4xl xl:text-6xl text-center text-sbam-2 font-mulish font-extrabold pb-10 self-center md:self-start">{title_2}</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 items-center md:items-start xl:justify-items-center">
                        <div className="xl:pl-18">
                            <h3 className="text-3xl font-normal pb-8 text-center relative self-center xl:text-start">
                                <span className="text-sbam-1 scratch">{where.title}</span>
                            </h3>
                            {where.elements.map((el, index) => {
                                return (
                                    <div className="pl-5 xl:pl-16 pb-8 md:pl-20 xl:pb-8" key={index}>
                                        <h4 className="text-lg font-extrabold font-mulish  text-sbam-1">{el.title}</h4>
                                        <p className="text-lg font-mulish text-sbam-1">
                                            {el.description}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="xl:pl-18">
                            <h3 className="text-3xl font-normal pb-8 text-center relative self-center xl:text-start">
                                <span className="text-sbam-1 scratch scratch-contact">{contatti.title}</span>
                            </h3>
                            <div className="pl-6 pb-8 text-sbam-1 font-mulish md:pl-20 xl:pl-8">

                                {contatti.contacts.map((nub, index) => {
                                    return (
                                    <p className="text-lg pb-2 " key={index}>{nub.title}</p>
                                    );
                                })}
                                {/* <p className="text-lg pb-2 ">
                                + 39 035 403525
                                </p>
                                <p className="text-lg pb-2 ">
                                + 338.4128614
                                </p> */}

                                {contatti.links.map((link) => {
                                    let linkTo = (link.type && link.type == "website") ? link.url : "mailto:"+link.title;
                                    return (
                                        <a href={linkTo} className="text-lg hover:underline pb-2 block">{link.title}</a>
                                    )
                                })}
                                {/* <p>
                                <Link className="text-lg hover:underline" to="/#aboutUs">info@fondazionecasaamica.org</Link> 
                                </p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Partner;
