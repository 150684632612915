import * as React from "react"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation , EffectCoverflow } from 'swiper';
import { graphql, useStaticQuery } from "gatsby";
import 'swiper/css/bundle';

import Slide1 from '../images/hero_2.jpg';
import Slide2 from '../images/hero_2.jpg';
import Slide3 from '../images/hero_2.jpg';

const Project = () => {

    const navigationPrevRef = React.useRef(null)
    const navigationNextRef = React.useRef(null)
    const data = useStaticQuery(graphql`
    query MyQuery {
        allProjectJson {
          nodes {
            slug
            title
            description
            cta
            slides {
                id
                title
                url
            }
          }
        }
      }
    `)
    const project = data.allProjectJson.nodes[0]

    const title = project.title
    const description = project.description
    const cta = project.cta
    const slides = project.slides

    const slides2 = [
        {   
            id: 1,
            title: "Appartamento 1",
            image: Slide1
        },
        {   
            id: 2,
            title: "Appartamento 2",
            image: Slide2
        },
        {   
            id: 3,
            title: "Appartamento 3",
            image: Slide3
        }
    ];
    const slidesElement = [];
    for(let i = 0; i < slides2.length; i++){
        slidesElement.push(
            <SwiperSlide key={slides2[i].id}>
                <img placeholder='none' className="min-h-[234px] w-full 2xl:min-h-[unset] 2xl:h-full" src={slides2[i].image} alt="Hero Image 2" />
                <div className="absolute bottom-0 left-0 bg-gradient-desc-project w-full h-[54px] 2xl:h-[120px] flex items-center 2xl:items-end 2xl:py-6 px-4">
                    <p className="text-white text-lg">{slides2[i].title}</p>   
                </div>
            </SwiperSlide>
        )
    }

    return (
        <div id="project" className="mb-[120px] mt-[120px]" >
            <div className="swiper-project overflow-hidden relative">
                <div className="flex flex-col  items-center justify-between mb-10">
                    <h2 className="text-4xl text-sbam-1 md:text-[40px] md:leading-[46px]">{title}</h2>
                    <div ref={navigationPrevRef} className="swiper-button-prev" />
                    <div ref={navigationNextRef} className="swiper-button-next" />
                </div>
                <Swiper
                    loop={true}
                    modules={[Navigation , EffectCoverflow]}
                    slidesPerView={1}
                    centeredSlides= { true }
                    navigation={{
                        prevEl: navigationPrevRef.current,
                        nextEl: navigationNextRef.current,
                    }}
                    onBeforeInit={(swiper) => {
                        swiper.params.navigation.prevEl = navigationPrevRef.current;
                        swiper.params.navigation.nextEl = navigationNextRef.current;
                    }}
                    breakpoints= {
                        {   
                            768: {
                                slidesPerView: 1.5,
                                centeredSlides: false,
                                spaceBetween: 24
                            },
                            1440: {
                                slidesPerView: 1.75,
                                centeredSlides: true,
                                spaceBetween: 24,
                            }
                        }
                    }
                    className="project-swiper"
                    >
                    {slidesElement}
                </Swiper>
            </div>
            
            <div className="mt-[120px] flex flex-col justify-center items-center">
                <h2 className="text-3xl text-center md:max-w-[525px] 2xl:max-w-[911px]" dangerouslySetInnerHTML={{ __html: description }}></h2>
                {/*{description} 
                <span className="before:block before:absolute paint before:bg-contain before:bg-no-repeat before:bg-[url('../images/tratto-verde-big.png')] relative inline-block">
                    <span className="relative font-bold">{description_bold}</span>
                </span> 
                {description_2}</h2>*/}
                <a href='/dimore/' className="mt-10  w-[212px] h-[42px] flex justify-center items-center bg-sbam-2 text-white font-mulish font-extrabold hover:bg-yellow-hover">{cta}</a>
            </div>
        </div>
    )
  }
  export default Project
