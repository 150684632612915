import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

const AboutUs = () => {

    const data = useStaticQuery(graphql`
    query AboutQuery {
        allAboutJson {
            nodes {
            slug
            title
            subtitle
            description
            year_title_1 
            year_description_1 
            year_title_2 
            year_description_2 
            year_title_3 
            year_description_3 
            year_title_4 
            year_description_4 
            }
        }
        }
    `)
    const project = data.allAboutJson.nodes[0]

    const title = project.title
    const subtitle = project.subtitle
    const description = project.description
    const year_title_1 = project.year_title_1
    const yaer_description_1 = project.year_description_1
    const year_title_2 = project.year_title_2
    const yaer_description_2 = project.year_description_2
    const year_title_3 = project.year_title_3
    const yaer_description_3 = project.year_description_3
    const year_title_4 = project.year_title_4
    const year_description_4 = project.year_description_4

    return (
        <div id="aboutUs" className="mb-[120px] mt-[120px] bg-yellow-bg container mx-auto px-6 pb-20  md:px-[120px] xl:grid xl:grid-cols-10">
            <div className="pt-[120px] xl:pt-[104px] flex flex-col justify-center items-start pb-[60px] xl:col-span-3 xl:justify-start">
                <h3 className="text-2xl text-center text-sbam-2 font-mulish font-extrabold pb-4">{title}</h3>
                <h2 className="text-5xl font-normal pb-10 md:text-[40px] md:leading-[46px] xl:max-w-[447px]" dangerouslySetInnerHTML={{ __html: subtitle }}></h2>
                <p className="text-lg font-mulish xl:max-w-[330px]">
                    {description}
                </p>
            </div>
            <div className="xl:col-span-6 xl:col-start-5 xl:pt-[360px]">
                <div className="flex justify-between md:justify-start pb-10 pl-1">
                    <div className="flex flex-col pl-24 xl:pl-24">
                        <h2 className="text-sbam-2 font-bold text-4xl xl:text-6xl relative before:block  before:content-['']  before:w-20 xl:before:w-32  before:h-6 xl:before:h-10 before:bg-no-repeat before:absolute  before:-left-24 xl:before:-left-40 before:top-2 before:bg-contain xl:before:top-4 before:bg-[url('../images/arrow-1.png')]">
                            {year_title_1}
                        </h2>
                        <p className="text-lg font-mulish pl-3 md:pl-6 xl:pl-14 md:max-w-[438px] xl:max-w-none">
                            {yaer_description_1}
                        </p>
                    </div>
                </div>
                <div className="flex justify-between  md:justify-start pb-10 pl-1">
                        {/* <svg width="72" height="16" className="h-[45px] min-w-[70px]" viewBox="0 0 72 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M58.3197 13.62C62.2011 12.8537 66.7415 10.2153 70.2851 8.3578M70.2851 8.3578C70.3533 8.32189 69.7338 7.58406 69.6844 7.52797C67.461 5.00945 61.9917 2.36909 58.8365 1.76374M70.2851 8.3578C49.2547 6.80694 25.4007 7.03869 1.84313 7.18454" stroke="#B3C841" strokeWidth="3.0975" strokeMiterlimit="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg> */}
                    <div className="flex flex-col pl-24 xl:pl-24">
                        <h2 className="text-sbam-2 font-bold text-4xl xl:text-6xl  relative before:block  before:content-['']  before:w-28 xl:before:w-28 before:h-6 xl:before:h-10 before:bg-no-repeat before:absolute  before:-left-24 xl:before:-left-36 before:top-2 before:bg-contain xl:before:top-4 before:bg-[url('../images/arrow-2.png')]">
                            {year_title_2}
                        </h2>
                        <p className="text-lg font-mulish pl-3 md:pl-6 xl:pl-14 md:max-w-[438px] xl:max-w-none ">
                            {yaer_description_2}
                        </p>
                    </div>
                </div>
                <div className="flex justify-between md:justify-start pb-10 pl-1 ">
                        {/* <svg width="72" height="16" className="h-[45px] min-w-[70px]" viewBox="0 0 72 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M58.3197 13.62C62.2011 12.8537 66.7415 10.2153 70.2851 8.3578M70.2851 8.3578C70.3533 8.32189 69.7338 7.58406 69.6844 7.52797C67.461 5.00945 61.9917 2.36909 58.8365 1.76374M70.2851 8.3578C49.2547 6.80694 25.4007 7.03869 1.84313 7.18454" stroke="#B3C841" strokeWidth="3.0975" strokeMiterlimit="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg> */}
                    <div className="flex flex-col pl-24 xl:pl-24">
                        <h2 className="text-sbam-2 font-bold text-4xl xl:text-6xl relative before:block  before:content-['']  before:w-20 xl:before:w-32 before:h-6 xl:before:h-10 before:bg-no-repeat before:absolute before:-left-24 xl:before:-left-40 before:top-2 before:bg-contain xl:before:top-4 before:bg-[url('../images/arrow-3.png')]">
                            {year_title_3}
                        </h2>
                        <p className="text-lg font-mulish pl-3 md:pl-6 xl:pl-14 md:max-w-[438px] xl:max-w-none">
                            {yaer_description_3}
                        </p>
                    </div>
                </div>
                <div className="flex justify-between md:justify-start pb-10 pl-1">
                        {/* <svg width="72" height="16" className="h-[45px] min-w-[70px]" viewBox="0 0 72 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M58.3197 13.62C62.2011 12.8537 66.7415 10.2153 70.2851 8.3578M70.2851 8.3578C70.3533 8.32189 69.7338 7.58406 69.6844 7.52797C67.461 5.00945 61.9917 2.36909 58.8365 1.76374M70.2851 8.3578C49.2547 6.80694 25.4007 7.03869 1.84313 7.18454" stroke="#B3C841" strokeWidth="3.0975" strokeMiterlimit="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg> */}
                    <div className="flex flex-col pl-24 xl:pl-24">
                        <h2 className="text-sbam-2 font-bold text-4xl xl:text-6xl relative before:block  before:content-['']  before:w-24 xl:before:w-36 before:h-6 xl:before:h-10 before:bg-no-repeat before:absolute  before:-left-28 xl:before:-left-44 before:top-2 before:bg-contain xl:before:top-4 before:bg-[url('../images/arrow-4.png')]">
                            {year_title_4}
                        </h2>
                        <p className="text-lg font-mulish pl-3 md:pl-6 xl:pl-14 md:max-w-[438px] xl:max-w-none">
                           {year_description_4}
                        </p>
                    </div>
                </div>

            </div>
        </div>
    );
};
export default AboutUs;
