import React, { useState } from 'react';
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";


const Header = () => {
    // var sideMenu = document.querySelector('#side-menu');
    function openMenu() {
        document.body.classList.add("overflow-hidden")
        setOpen(true)
    }

    function closeMenu() {
        document.body.classList.remove("overflow-hidden")
        setOpen(false)
    }

    const [open, setOpen] = useState(false);
    return (

            <header className="flex flex-col justify-center items-center pb-[60px] xl:px-24">
                <div id="side-menu" 
    className={`fixed top-0 inset-y-0 left-0 transform ${open ? "" : "-translate-x-full"}   w-screen h-screen z-50 p-6 flex flex-col  items-center space-y-10 text-sbam-1 duration-300 bg-white  xl:hidden font-mulish `}>
                    <button  className="self-start text-right text-4xl " onClick={closeMenu}>&times;</button>
                    <Link className=" text-sbam-1 text-xl md:text-2xl" onClick={closeMenu} to="/#aboutUs">Chi siamo</Link>
                    <Link className="text-sbam-1 text-xl md:text-2xl"  onClick={closeMenu} to="/#project">Il progetto</Link>
                    <Link className=" text-sbam-1 text-xl md:text-2xl" onClick={closeMenu} to="/#partners">Partners</Link>
                    <Link className="mt-10  w-[212px] h-[42px] flex justify-center items-center bg-sbam-2 hover:bg-yellow-hover text-white font-mulish font-extrabold text-lg" onClick={closeMenu} to="/#contact-us">CONTATTACI</Link>
                    <StaticImage placeholder='none' src="../images/sbam_logo.png" className="logo-menu-mob h-[40px] md:h-[52px]" alt="Logo Sbam" />

                </div>
                <div className="flex justify-between py-3 w-full">
                {/* <button className="cursor-pointer text-4xl md:hidden" onClick={openMenu}>&#9776;</button> */}
                    <button className="cursor-pointer text-4xl xl:hidden" onClick={openMenu}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-9 h-9">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                        </svg>
                    </button>
                    <StaticImage placeholder='none' id="logo" src="../images/sbam_logo.png" className="logo-menu-mob h-[65px] w-[107px]" alt="Logo Sbam" />
                    <div id="main-menu" className="0 p-5 flex-row  items-center text-sbam-1 space-x-14 hidden xl:flex font-mulish ">
                            <Link className=" text-sbam-1 text-lg" to="/#aboutUs">Chi siamo</Link>
                            <Link className="text-sbam-1 text-lg" to="/#project">Il progetto</Link>
                            <Link className=" text-sbam-1 text-lg" to="/#partners">Partners</Link>
                            <Link className="w-[212px] h-[42px] flex justify-center items-center bg-sbam-2 hover:bg-yellow-hover text-white font-mulish font-extrabold text-lg" to="/#contact-us">CONTATTACI</Link>
                    </div>
                </div>  
            </header>

    );
};
export default Header;
