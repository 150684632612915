import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link, graphql, useStaticQuery  } from "gatsby";
const Footer = () => {


    const data_footer = useStaticQuery(graphql`
    query FooterQuery {
        allFooterJson {
          nodes {
            slug
            title
            content
          }
        }
      }
    `)
    const footer = data_footer.allFooterJson.nodes[0]
    // const slug = footer.slug
    const title = footer.title
    const content = footer.content

    return (

        <footer className="flex flex-col justify-center items-center pb-[60px]">
            <StaticImage placeholder='none' className="mb-4 2xl:mb-12" src="../images/brush-highlight-big.png" alt="border footer" />
            <div className="2xl:flex 2xl:flex-col 2xl:w-full 2xl:items-start">
                <h2 className="text-3xl font-normal pb-10 text-center 2xl:pb-6">
                    {title}
                </h2>
                <div className="2xl:flex 2xl:flex-row 2xl:w-full 2xl:items-end 2xl:justify-between">
                    <div className="pl-6 text-sbam-1 font-mulish text-center pb-10 md:pb-8">
                        <p className="text-lg text-center md:text-left" dangerouslySetInnerHTML={{ __html: content }}></p>
                    </div>
                    <div className="pl-6 pb-8 text-sbam-1 font-mulish text-center 2xl:text-right">
                        <p className="text-lg pb-4 2xl:pb-2 hover:underline">
                            <Link to="/#">
                                PRIVACY POLICY
                            </Link>
                        </p>
                        <p className="text-lg hover:underline">
                            <Link to="/#">
                            COOKIE POLICY
                            </Link>
                        </p>
                    </div>   
                </div>
            </div>
        </footer>
    );
};
export default Footer;
