import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination  } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { graphql, useStaticQuery } from "gatsby";

import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";

const Hero = () => {

    const data = useStaticQuery(graphql`
    query HeroQuery {
        allHeroJson {
          nodes {
            title
            title_green
            slug
            description
            img_title
            img_description
            slides {
              id
              title
              description
              url
            }
          }
        }
      }
    `)

    const hero = data.allHeroJson.nodes[0]
    const title = hero.title
    const title_green = hero.title_green
    const description = hero.description
    const img_title = hero.img_title
    const img_description = hero.img_description
    const slides = hero.slides

    return (
        <div className="">
            <div className="pb-[120px] md:px-24 xl:grid xl:grid-cols-10 " >
                <div className="xl:col-span-4">
                    <h1 className="text-3xl md:text-[40px] md:leading-[116%] xl:text-7xl text-sbam-1 font-medium pb-6 ">
                    {title}<span className="text-sbam-2 font-bold">{title_green}</span>
                    </h1>
                    <p className="pb-6 font-mulish md:text-lg xl:max-w-[330px]">{description}</p>
                </div>
                {/* <StaticImage src="../images/hero.jpg" id="hero-img" className="md:w-full xl:col-span-6 xl:h-[800px]" alt="Hero Image" /> */}
                <MouseParallaxContainer useWindowMouseEvents="true" className="h-[328px] md:w-full xl:col-span-6 xl:h-[800px] relative xl:-mt-[90px]">
                    <StaticImage placeholder='none' src="../images/white-apartment-drawing.png" id="hero-img" className="h-full md:w-full xl:col-span-6 xl:h-[800px] absolute top-0 left-0" alt="Hero Image" />

                        <MouseParallaxChild
                            factorX={0.015}
                            factorY={0.015}
                            updateStyles={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "auto",
                                height: "100%",
                                mixBlendMode: "multiply",
                                position:'absolute',
                                top:"0",
                                left:"0"
                            }}
                            >
                            <div>
                            <StaticImage placeholder='none' src="../images/white-apartment-light.png" id="hero-gif" className="h-full md:w-full xl:col-span-6 xl:h-[800px]" alt="Hero Image" />
                            </div>

                    </MouseParallaxChild>
                </MouseParallaxContainer>
            </div>
            <div className="pb-[120px] md:px-24 lg:grid lg:grid-cols-10 lg:gap-4">
                <div className="xl:col-span-5 xl:order-2 xl:flex xl:flex-col xl:justify-center">
                    <div className="swiper-hero overflow-hidden relative">
                    <Swiper
                        modules={[Pagination]}
                        slidesPerView={1}
                        centeredSlides= {true}
                        pagination={{ clickable: true }}
                        className="hero-swiper"
                        >
                            {slides.map(slide => {
                                return (
                                    <SwiperSlide className="p-2" key={slide.id}>
                                        <h2 className="text-4xl pb-4 max-w-[75%] lg:text-[40px] lg:leading-[116%]" dangerouslySetInnerHTML={{ __html: slide.title }}></h2>
                                        <p className="font-mulish md:text-lg"> {slide.description}</p>   
                                    </SwiperSlide>
                                );
                            })}
                    </Swiper>
                    </div>
                </div>
                <div className="md:flex md:items-center md:justify-center xl:justify-start xl:col-span-5">
                    <StaticImage placeholder='none' className="mt-16 bg-cover md:h-[350px] w-full xl:h-[450px] xl:mt-0" src="../images/hero_2.jpg" alt="Hero Image 2" />
                </div>
            </div>
            <div className="bg-no-repeat bg-center bg-gradient-img-hero  bg-cover md:bg-cover xl:bg-cover min-h-[468px] md:min-h-[360px] xl:min-h-[578px] xl:bgcl-cover flex items-center flex-col justify-end pb-12 xl:mx-24 xl:pb-16">
                <h2 className="text-4xl md:text-[40px] md:leading-[46px] text-white text-center xl:text-6xl xl:pb-4" dangerouslySetInnerHTML={{ __html: img_title }}></h2>
                <p className="text-white text-center font-mulish font-medium text-lg px-2 md:max-w-[440px] xl:text-xl xl:max-w-[420px]">{img_description}</p>
            </div>
        </div>
    )
  }
  export default Hero
